exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-angels-guardian-club-js": () => import("./../../../src/pages/angels-guardian-club.js" /* webpackChunkName: "component---src-pages-angels-guardian-club-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-service-areas-js": () => import("./../../../src/pages/service-areas.js" /* webpackChunkName: "component---src-pages-service-areas-js" */),
  "component---src-pages-vcard-anton-nemirov-js": () => import("./../../../src/pages/vcard/anton-nemirov.js" /* webpackChunkName: "component---src-pages-vcard-anton-nemirov-js" */),
  "component---src-templates-blog-list-js": () => import("./../../../src/templates/blog-list.js" /* webpackChunkName: "component---src-templates-blog-list-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-coupons-list-js": () => import("./../../../src/templates/coupons-list.js" /* webpackChunkName: "component---src-templates-coupons-list-js" */),
  "component---src-templates-reviews-list-js": () => import("./../../../src/templates/reviews-list.js" /* webpackChunkName: "component---src-templates-reviews-list-js" */),
  "component---src-templates-sub-page-js": () => import("./../../../src/templates/sub-page.js" /* webpackChunkName: "component---src-templates-sub-page-js" */)
}

